import {
  ITenantCreateForm,
  ITenantParameters,
  ITenantUpdateForm,
  ITenantUser,
  ITenantUserUpdate,
} from "@/models/Tenant";
import {
  ITenantUserParameters,
  ITenantUserPassword,
} from "@/models/TenantUser";
import { IResponse } from "@/services/axios/entities";
import { catchAxiosError } from "@/services/axios/error";
import HttpClient from "@/services/axios/instance";

const TENANT = "Tenants";

export class TenantAPI extends HttpClient {
  constructor() {
    super(process.env.VUE_APP_DOMAIN_API_SERVER);
  }

  public createTenant = async (data: ITenantCreateForm): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .post(TENANT, data)
      .catch(catchAxiosError);

    return response;
  };

  public getListTenant = async (
    params?: ITenantParameters
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(TENANT, { params })
      .catch(catchAxiosError);

    return response;
  };

  public getTenant = async (TenantId: string | number): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${TENANT}/${TenantId}`)
      .catch(catchAxiosError);

    return response;
  };

  public updateTenant = async (
    TenantId: string | undefined,
    data: ITenantUpdateForm
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .put(`${TENANT}/${TenantId}`, data)
      .catch(catchAxiosError);

    return response;
  };

  public createUser = async (
    TenantId: string,
    data: ITenantUser
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .post(`${TENANT}/${TenantId}/users`, data)
      .catch(catchAxiosError);

    return response;
  };

  public updateUser = async (
    TenantId: string,
    UserId: string,
    data: ITenantUserUpdate
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .put(`${TENANT}/${TenantId}/users/${UserId}`, data)
      .catch(catchAxiosError);

    return response;
  };
  public getListTenantUser = async (
    TenantId: string | number,
    params: ITenantUserParameters
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${TENANT}/${TenantId}/users`, { params })
      .catch(catchAxiosError);

    return response;
  };

  public getTenantUser = async (
    TenantId: string | number,
    UserId: string | number
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${TENANT}/${TenantId}/users/${UserId}`)
      .catch(catchAxiosError);

    return response;
  };

  public updatePassTenantUser = async (
    TenantId: string,
    UserId: string,
    data: ITenantUserPassword
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .post(`${TENANT}/${TenantId}/users/${UserId}/passwords`, data)
      .catch(catchAxiosError);

    return response;
  };
  public sendActivationEmailTenantUser = async (
    TenantId: string,
    UserId: string
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .post(`${TENANT}/${TenantId}/users/${UserId}/send-activation-email`)
      .catch(catchAxiosError);
    return response;
  };
}

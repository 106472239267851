import { IPagination } from "@/models/Pagination";
import {
  ITenantCreateForm,
  ITenantDTO,
  ITenantParameters,
  ITenantUpdateForm,
} from "@/models/Tenant";
import { ErrorResponse } from "@/services/axios/error";
import { TenantAPI } from "@/services/tenant";
import store from "@/store";
import { ElMessage } from "element-plus/lib/components/message";
import isEmpty from "lodash/isEmpty";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import { IResponse } from "@/services/axios/entities";

const name = "TenantModule";

if (store.state[name]) {
  store.unregisterModule(name);
}

@Module({ dynamic: true, name, namespaced: true, store })
class TenantModule extends VuexModule {
  private pagination = {} as IPagination;

  private dataGetListTenant = [] as ITenantDTO[];
  private errorGetListTenant = {} as ErrorResponse;

  private detailTenant = {} as ITenantDTO;
  private errorDetailTenant = {} as ErrorResponse;
  private errorCreateTenant = {} as ErrorResponse;
  private errorUpdateTenant = {} as ErrorResponse;

  get hasCreateErrorMsg() {
    return !isEmpty(this.errorCreateTenant);
  }
  get listDataTenant() {
    return this.dataGetListTenant;
  }

  get TenantGetter() {
    return this.detailTenant;
  }

  get errorDetailTenantGetter() {
    return !isEmpty(this.errorDetailTenant);
  }
  @Mutation
  createTenantSuccess(data: any) {
    ElMessage.success("Create new Tenant success.");
    this.errorCreateTenant = {} as ErrorResponse;
    this.detailTenant = data;
  }
  @Mutation
  createTenantFailure(error: ErrorResponse) {
    ElMessage.error(error.message);
    this.errorCreateTenant = error;
  }
  @Action
  async createTenantAction(payload: ITenantCreateForm) {
    const servicesTenantAPI = new TenantAPI();
    const { data, error } = await servicesTenantAPI.createTenant(payload);

    if (error) {
      this.createTenantFailure(error);
    } else {
      this.createTenantSuccess(data);
    }
  }
  @Mutation
  getListTenantSuccess(data: any) {
    this.errorGetListTenant = {} as ErrorResponse;
    const { Collection, ...rest } = data;
    this.dataGetListTenant = Collection;
    this.pagination = rest;
  }
  @Mutation
  getListTenantFailure(error: ErrorResponse) {
    this.errorGetListTenant = error;
  }
  @Action
  async getListTenantAction(params?: ITenantParameters) {
    const servicesTenantAPI = new TenantAPI();
    const { data, error } = await servicesTenantAPI.getListTenant(params);

    if (error) {
      this.getListTenantFailure(error);
    } else {
      this.getListTenantSuccess(data);
    }
  }
  @Mutation
  getTenantFailure(error: ErrorResponse) {
    this.errorDetailTenant = error;
  }
  @Mutation
  getTenantSuccess(data: any) {
    this.errorDetailTenant = {} as ErrorResponse;
    this.detailTenant = data;
  }
  @Action
  async getTenant(payload: { TenantId: string }): Promise<IResponse> {
    const { TenantId } = payload;
    const servicesTenantAPI = new TenantAPI();
    const { data, error } = await servicesTenantAPI.getTenant(TenantId);

    if (error) {
      this.getTenantFailure(error);
      return { error };
    } else {
      this.getTenantSuccess(data);
      return { data };
    }
  }
  @Mutation
  updateTenantSuccess() {
    ElMessage.success("Updated Tenant success.");
    this.errorUpdateTenant = {} as ErrorResponse;
  }
  @Mutation
  updateTenantFailure(error: ErrorResponse) {
    this.errorUpdateTenant = error;
  }
  @Action
  async updateTenantAction(payload: ITenantUpdateForm) {
    const servicesTenantAPI = new TenantAPI();
    const { error } = await servicesTenantAPI.updateTenant(payload.Id, payload);

    if (error) {
      this.updateTenantFailure(error);
    } else {
      this.updateTenantSuccess();
    }
  }
  @Mutation
  resetDetailTenant() {
    this.detailTenant = {} as ITenantDTO;
  }
}
export default getModule(TenantModule);

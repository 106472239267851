import { createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "my-4 px-10 py-1" }
const _hoisted_2 = { className: "grid grid-cols-1 2xl:gap-4 gap-2 sm:grid-cols-2 xl:grid-cols-3 2xl:py-4 py-3" }
const _hoisted_3 = { class: "col-span-2 flex flex-col lg:flex-row" }
const _hoisted_4 = {
  class: "w-full lg:w-2/3 2xl:mr-5 mr-3",
  "data-aos": "fade-down"
}
const _hoisted_5 = { class: "form-group inputfield inputfield__underline inputfield__withPrefixIcon self-end w-1/2 lg:w-1/3" }
const _hoisted_6 = { class: "el-icon" }
const _hoisted_7 = { class: "flex items-end justify-end" }
const _hoisted_8 = { class: "grid 2xl:gap-4 gap-2 lg:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-6 pt-8" }
const _hoisted_9 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode($setup["CarbonEmissionsByScopeBar"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_el_input, {
            modelValue: $setup.searching,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.searching) = $event)),
            placeholder: "Search",
            onInput: $setup.handleSearchTenant
          }, {
            prefix: _withCtx(() => [
              _createElementVNode("i", _hoisted_6, [
                _createVNode($setup["BaseSvgIcon"], {
                  class: "inline-block text-white w-5 h-5",
                  name: "search"
                })
              ])
            ]),
            _: 1
          }, 8, ["modelValue", "onInput"])
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("button", {
          type: "button",
          class: "btn btn--red-primary",
          onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => ($setup.router.push('/create')), ["prevent"]))
        }, " Create New Tenant ")
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.dataListTenant, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          key: item.Id,
          class: "box 2xl:h-72 h-72 w-full bg-cafe-dark relative overflow-hidden p-0",
          "data-aos": "zoom-out-up",
          "data-aos-duration": "600"
        }, [
          _createElementVNode("div", {
            class: "p-0 w-full cursor-pointer",
            style: { height: '210px' },
            onClick: _withModifiers(($event: any) => ($setup.handleClickDetail(item)), ["prevent"])
          }, [
            _createElementVNode("div", {
              class: "w-full h-full object-fill object-center bg-cover",
              style: _normalizeStyle({
              backgroundImage: `url(https://images.unsplash.com/photo-1618167117202-ec92175ff847?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3132&q=80)`,
              backgroundBlendMode: 'multiply',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            })
            }, null, 4),
            _createElementVNode("h1", {
              class: _normalizeClass([
              'mt-4 pl-4 text-left text-lg 2xl:text-xl font-bold',
              _ctx.styles['text-gold'],
            ])
            }, _toDisplayString(item.Name), 3)
          ], 8, _hoisted_9)
        ]))
      }), 128))
    ])
  ]))
}